import React, { useRef } from "react";
import { Box, Text, Grid } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Layout from "../components/Layout";
import useHover from "../hooks/useHover";
import HeroComponent from "../components/HeroComponent";
import ContactComponent from "../components/ContactComponent";
import InView from "react-intersection-observer";

const MotionText = motion(Text);

const OurValuesPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Our Values
            </motion.span>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
          fontSize={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, delay: 0.6, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            What makes us Aevias
          </motion.span>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/aevias-hero-two.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box ref={scrollHere} as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "8rem" }}
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "80px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-one.png"
                        alt="aevias values one"
                      />
                    </Box>
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                        marginBottom="12"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.4 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We value
                        </MotionText>{" "}
                        the power of human connections and relationships; this
                        is the foundation of how we approach everything we do.
                      </Text>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We listen
                        </MotionText>{" "}
                        to our clients, our supplier partners, our industry
                        peers to develop understanding so we may bring value to
                        their businesses. We work with integrity; we do what is
                        right.
                      </Text>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>

        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "6rem", md: "8rem" }}
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column-reverse"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "80px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                        marginBottom="12"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.4 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We believe
                        </MotionText>{" "}
                        in responsible business practices, and we embrace
                        opportunities to contribute to sustainability, our
                        community and humanity.
                      </Text>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We value our people.
                        </MotionText>{" "}
                        We encourage curiosity and empower through learning and
                        professional development.
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-two.png"
                        alt="aevias values two"
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>

        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "8rem" }}
              mb="8rem"
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                {inView && (
                  <Grid
                    display={{ base: "flex", md: "grid" }}
                    flexDir="column"
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "80px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box display="flex" justifyContent="center">
                      <img
                        draggable="false"
                        src="/img/ae-values-three.png"
                        alt="aevias values three"
                      />
                    </Box>
                    <Box>
                      <Text
                        as="p"
                        fontSize={{ base: "18px", md: "24px" }}
                        lineHeight={{ base: "24px", md: "32px" }}
                        fontFamily="Montserrat"
                      >
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          fontWeight="500"
                          color="secondary.500"
                          display="inline-block"
                        >
                          We have a duty of care
                        </MotionText>{" "}
                        to our clients, their guests and each other. We adhere
                        to the latest safety and health regulations. The entire
                        Aevias team has been certified on Pandemic On-Site
                        Protocols.
                      </Text>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box>
        <ContactComponent />
      </Box>
    </Layout>
  );
};

export default OurValuesPage;
